import {
  InputLabel,
  Switch,
  Typography,
  Stack, ListItemSecondaryAction, IconButton, TextField, Button, Select, CardHeader, Card, CardContent, FormControl
} from "@mui/material";
import { Controller } from 'react-hook-form';
import Divider from "@mui/material/Divider";
import { StrategyType } from "./deal";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import CategorySearchAutocomplete from "./categorySearchAutoComplete";
import { useState } from "react";
import PublisherSearchAutocomplete from "./publisherSearchAutoComplete";
import BulkDialog from "../shared/bulkDialog";
//import { IncludeExcludeOverlay } from "./strategyOverlay";
import MenuItem from "@mui/material/MenuItem";
import { IncludeExcludeOverlay } from "./strategyOverlay";

interface AddSupplyProps {
  control: any;
  setValue: Function
  getValues: Function
  selectedPublishersSearchItems: any
  selectedContentsSearchItems: any
  strategyPublisherValue: StrategyType
  strategyCategoryValue: StrategyType
  selectedCategories: any
  strategyBlockedCategoryValue: StrategyType
  selectedBlockedCategories: any
  strategyDomainsOrBundlesValue: StrategyType
  selectedDomainsOrBundles: any

}


export interface CategoryItem {
  "category-id": number
  "category-name": string
  "category-description": string
  "category-parent": number
}

export interface PublisherItem {
  "unit-id": number
  "app-name": string
  "publisher-id": number
  "publisher-name": string
  "ad-unit-name": string
  "publisher-type": string
}

export const PublisherType = {
  Publisher: "Publisher",
  App: "App",
  AdUnit: "Ad Unit",
  AdSpot: "Ad Spot"
}

export default function AddSupply( props:AddSupplyProps) {
  const { control, setValue, getValues, selectedPublishersSearchItems, selectedContentsSearchItems, strategyPublisherValue,strategyCategoryValue, selectedCategories,
    strategyBlockedCategoryValue, selectedBlockedCategories, strategyDomainsOrBundlesValue, selectedDomainsOrBundles} = props

  const combinedPublisherItems = [...selectedPublishersSearchItems, ...selectedContentsSearchItems];
  const [openDialogBlockedCategories, setOpenDialogBlockedCategories] = useState(false)
  const [openDialogCategories, setOpenDialogCategories] = useState(false)
  const [openDialogBundles, setOpenDialogBundles] = useState(false)
  const [tempDomainOrBundleInput, setTempDomainOrBundleInput] = useState("");
  const [inputError, setInputError] = useState("");
  const publishersAdUnits = "Publishers and Ad Units"
  const categories = "Categories"
  const blockedCategories = "Blocked Categories"
  const bundles = "Bundles"

  const isValidDomainOrBundleInput = (value: string) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      return false;
    }

    return /^[0-9]+$/i.test(trimmedValue) ||
      /^([A-Za-z]{1}[A-Za-z\d_]*\.)+[A-Za-z][A-Za-z\d_]*$/i.test(trimmedValue);
  };

  const deleteBlockedCategoryItem = (itemIndex:number) => {
    const currentItems = getValues("deal.conditions-json.blockedCategories.value") || [];

    const updatedItems = currentItems.filter((_, index:number) => index !== itemIndex);

    setValue("deal.conditions-json.blockedCategories.value", updatedItems, { shouldValidate: true });
  };

  function renderBlockedCategoryRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = selectedBlockedCategories[index];
    if (!item) {
      return null;
    }
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item["category-name"]} />
          <ListItemText primary={item["category-description"]} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deleteBlockedCategoryItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }

  function renderDomainOrBundleRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = selectedDomainsOrBundles[index];
    if (!item) {
      return null;
    }
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deleteDomainOrBundleItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }

  const deleteDomainOrBundleItem = (itemIndex:number) => {
    const currentItems = getValues("deal.conditions-json.domainOrBundle.value") || [];

    const updatedItems = currentItems.filter((_, index:number) => index !== itemIndex);

    setValue("deal.conditions-json.domainOrBundle.value", updatedItems, { shouldValidate: true });
  };

  const checkValidationsBundlesBulk = async (values: string[]): Promise<string[]> => {
    return  values.filter(isValidDomainOrBundleInput)
  }

  const handleAddDomainOrBundle = (bulkResult?: string | null) => {
    if (bulkResult || isValidDomainOrBundleInput(tempDomainOrBundleInput)) {
      const currentArray = getValues("deal.conditions-json.domainOrBundle.value") || [];
      const itemToInsert = bulkResult  !== null ? bulkResult : tempDomainOrBundleInput
      // Check if the tempDomainOrBundleInput already exists in the currentArray
      const isDuplicate = currentArray.some((item:string) => item === itemToInsert);

      if (!isDuplicate) {
        setValue("deal.conditions-json.domainOrBundle.value", [...currentArray, itemToInsert]);
        setTempDomainOrBundleInput(''); // Reset the temporary input
        setInputError(''); // Clear any error messages
      } else {
        bulkResult === null ?  setInputError("Duplicate entry. Please enter a unique Android or IOS bundle id.") : null
      }
    } else {
      bulkResult === null ? setInputError("Invalid bundle, please enter a valid Android or IOS bundle id") : null
    }
  };


  const deleteCategoryItem = (itemIndex:number) => {
    const currentItems = getValues("deal.conditions-json.categories.value") || [];

    const updatedItems = currentItems.filter((_, index:number) => index !== itemIndex);

    setValue("deal.conditions-json.categories.value", updatedItems, { shouldValidate: true });
  };

  const deletePublisherItem = (itemIndex:number, itemType:string) => {
    let fieldPath;
    let currentItems;

    if (itemType === "Publisher") {
      currentItems = getValues("deal.conditions-json.publishers.value") || [];
      fieldPath = "deal.conditions-json.publishers.value";
    } else {
      const publisherItems = getValues("deal.conditions-json.publishers.value") || [];
      currentItems = getValues("deal.conditions-json.contents.value") || [];
      fieldPath = "deal.conditions-json.contents.value";
      // Adjust the index for contents array
      itemIndex = itemIndex - publisherItems.length;
    }

    const updatedItems = currentItems.filter((_, index:number) => index !== itemIndex);
    setValue(fieldPath, updatedItems, { shouldValidate: true });
  };


  function renderCategoryRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = selectedCategories[index];
    if (!item) {
      return null;
    }
    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={item["category-name"]} />
          <ListItemText primary={item["category-description"]} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deleteCategoryItem(index)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }

  function renderPublisherRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const item = combinedPublisherItems[index];
    if (!item) {
      return null;
    }

    const itemType:string = item["publisher-type"];

    let primaryText;
    switch (itemType) {
      case "Publisher":
        primaryText = `${item["publisher-name"]} (${item["publisher-id"]}) (${itemType})`;
        break;
      case "App":
        // For 'App' type, display as 'Ad Unit'
        primaryText = `${item["publisher-name"]} (${item["publisher-id"]}) - ${item["ad-unit-name"]} (Ad Unit)`;
        break;
      default:
        // For other types, include the publisher-type in the display
        primaryText = `${item["publisher-name"]} (${item["publisher-id"]}) - ${item["ad-unit-name"]} (${itemType})`;
    }

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton>
          <ListItemText primary={primaryText} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => deletePublisherItem(index, itemType)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      </ListItem>
    );
  }


  const [blockedCategoryAutocompleteKey, setBlockedCategoryAutocompleteKey] = useState(0);

  const handleBlockedCategoryOptionSelect = (item: CategoryItem | null) => {
    if (item) {
      let currentItems = getValues("deal.conditions-json.blockedCategories.value") || []

      // Check if the selected item's parent is in the list
      const parentIndex = currentItems.findIndex((ci: CategoryItem) => ci["category-id"] === item["category-parent"]);
      if (parentIndex > -1) {
        // Remove the parent if found
        currentItems.splice(parentIndex, 1);
      } else {
        // Check if the selected item is a parent of any item in the list
        currentItems = currentItems.filter((ci: CategoryItem) => ci["category-parent"] !== item["category-id"]);
      }

      const isDuplicate = currentItems.some((ci: CategoryItem) => ci["category-id"] === item["category-id"]);
      if (!isDuplicate) {
        setValue("deal.conditions-json.blockedCategories.value", [...currentItems, item]);
        setBlockedCategoryAutocompleteKey(0)
      } else {

        setBlockedCategoryAutocompleteKey(prev => prev + 1)
      }
    }
  };

  const [categoryAutocompleteKey, setCategoryAutocompleteKey] = useState(0);
  const [publisherAutocompleteKey, setPublisherAutocompleteKey] = useState(0);

  const handleCategoryOptionSelect = (item: CategoryItem | null) => {
    if (item) {
      let currentItems = getValues("deal.conditions-json.categories.value") || [];

      // Check if the selected item's parent is in the list
      const parentIndex = currentItems.findIndex((ci: CategoryItem) => ci["category-id"] === item["category-parent"]);
      if (parentIndex > -1) {
        // Remove the parent if found
        currentItems.splice(parentIndex, 1);
      } else {
        // Check if the selected item is a parent of any item in the list
        currentItems = currentItems.filter((ci: CategoryItem) => ci["category-parent"] !== item["category-id"]);
      }

      const isDuplicate = currentItems.some((ci: CategoryItem) => ci["category-id"] === item["category-id"]);

      if (!isDuplicate) {
        setValue("deal.conditions-json.categories.value", [...currentItems, item]);
        setCategoryAutocompleteKey(0);
      } else {

        setCategoryAutocompleteKey(prev => prev + 1);
      }
    }
  };
  const fetchBulkData = async (parsedValues: string[]) => {
    try {
      const response = await fetch(`/api/deals/get-bulk-categories`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(parsedValues),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const responseData = await response.json()
      return responseData

    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const incrementKey = () => {
    setPublisherAutocompleteKey(prevKey => {
      const newKey = prevKey + 1;
      if (newKey >= 10) {
        return 0; // Reset if the key reaches 10
      }
      return newKey;
    });
  };

  const handlePublisherOptionSelect = (items: PublisherItem[] | null) => {
    if (items && items.length > 0) {
      let publishersPath = "deal.conditions-json.publishers.value";
      let contentsPath = "deal.conditions-json.contents.value";
      let publishers = getValues(publishersPath) || [];
      let contents = getValues(contentsPath) || [];

      items.forEach(item => {
        if (item["publisher-type"] === PublisherType.Publisher) {
          contents = contents.filter((ci: PublisherItem) => ci["publisher-id"] !== item["publisher-id"]);
          setValue(contentsPath, contents);

          if (!publishers.some((pi: PublisherItem) => pi["publisher-id"] === item["publisher-id"])) {
            publishers.push(item);
            setValue(publishersPath, publishers); // Update the publishers list
          }
        } else {
          let publisherIndex = publishers.findIndex((pi: PublisherItem) => pi["publisher-id"] === item["publisher-id"]);
          if (publisherIndex !== -1) {
            publishers.splice(publisherIndex, 1);
            setValue(publishersPath, publishers); // Update the publishers list
          }

          if (!contents.some((ci: PublisherItem) => ci["unit-id"] === item["unit-id"])) {
            contents.push(item);
            setValue(contentsPath, contents); // Update the contents list
          }
        }
        incrementKey();
      });
    }
  };

  return (
    <Card variant={"outlined"} style={{marginTop:12}}>
      <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Participating Supply"} />
      <CardContent>
      <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.publishers.strategy"} name={publishersAdUnits} >
      <Typography style={{paddingTop: 12, paddingBottom:24}}>{publishersAdUnits}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Controller
          name="deal.conditions-json.publishers.strategy"
          control={control}
          render={({ field }) => (
            <FormControl style={{width: 140}}>
              <InputLabel id="add-publishers-i-e-label">Include/Exclude</InputLabel>
            <Select labelId={"add-publishers-i-e-label"} id={"add-publishers-i-e"}  {...field} value={field.value} label="Include/Exclude" onChange={() => {
              const value = field.value === StrategyType.include
                ? StrategyType.exclude
                : StrategyType.include;
              field.onChange(value);
            }}>
              <MenuItem value={StrategyType.include}>Include</MenuItem>
              <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
            </Select>
            </FormControl>
          )}
        />
        <Controller
          name="deal.conditions-json.publishers.value"
          control={control}
          render={({ field }) => (
            <PublisherSearchAutocomplete
              key={publisherAutocompleteKey}
              selectedOption={field.value}
              onOptionSelect={(items) => handlePublisherOptionSelect(items)}
            />
          )}
        />
      </Stack>
      <FixedSizeList height={300}
                     itemSize={46}
                     style={{ border: `1px solid ${strategyPublisherValue === 'exclude'  ? 'red' : strategyPublisherValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
                     itemCount={combinedPublisherItems.length}
                     overscanCount={5}
                     itemData={combinedPublisherItems}>
        {renderPublisherRow}
      </FixedSizeList>
      </IncludeExcludeOverlay>
        <Divider/>
      <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.categories.strategy"} name={categories} >
        <Typography style={{paddingTop: 12, paddingBottom:24}}>{categories}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Controller
          name="deal.conditions-json.categories.strategy"
          control={control}
          render={({ field }) => (
            <FormControl style={{width: 140}}>
              <InputLabel id="add-cat-i-e-label">Include/Exclude</InputLabel>
            <Select labelId={"add-cat-i-e-label"} id={"add-cat-i-e"} {...field} value={field.value} label="Include/Exclude" onChange={() => {
              const value = field.value === StrategyType.include
                ? StrategyType.exclude
                : StrategyType.include;
              field.onChange(value);
            }}>
              <MenuItem value={StrategyType.include}>Include</MenuItem>
              <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
            </Select>
            </FormControl>
          )}
        />
        <Controller
          name="deal.conditions-json.categories.value"
          control={control}
          render={({ field }) => (
            <>
            <CategorySearchAutocomplete
              key={categoryAutocompleteKey}
              selectedOption={field.value || null}
              onOptionSelect={(item) => handleCategoryOptionSelect(item)}
            />
              <Button onClick={() => setOpenDialogCategories(true)}>
                Add Categories in bulk
              </Button>
              {openDialogCategories ? (
                <BulkDialog open={openDialogCategories} displayName={"category-name"} fetchData={fetchBulkData}  setOpen={setOpenDialogCategories} title={"Categories"} control={control} selectedData={selectedCategories}  handleSave={handleCategoryOptionSelect}/>
              ) : null}
            </>
          )}
        />
      </Stack>
      <FixedSizeList height={300}
                     itemSize={46}
                     style={{ border: `1px solid ${strategyCategoryValue === 'exclude'  ? 'red' : strategyCategoryValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
                     itemCount={selectedCategories.length}
                     overscanCount={5}
                     itemData={selectedCategories}>
        {renderCategoryRow}
      </FixedSizeList>
      </IncludeExcludeOverlay>
        <Divider/>
      <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.blockedCategories.strategy"} name={blockedCategories} >

        <Typography style={{paddingTop: 12, paddingBottom:24}}>{blockedCategories}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Controller
          name="deal.conditions-json.blockedCategories.strategy"
          control={control}
          render={({ field }) => (
            <FormControl style={{width: 140}}>
              <InputLabel id="add-bcat-i-e-label">Include/Exclude</InputLabel>
            <Select id={"add-bcat-i-e"} labelId={"add-bcat-i-e-label"} {...field} value={field.value} label="Include/Exclude" onChange={() => {
              const value = field.value === StrategyType.include
                ? StrategyType.exclude
                : StrategyType.include;
              field.onChange(value);
            }}>
              <MenuItem value={StrategyType.include}>Include</MenuItem>
              <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
            </Select>
            </FormControl>
          )}
        />
        <Controller
          name="deal.conditions-json.blockedCategories.value"
          control={control}
          render={({ field }) => (
            <>
            <CategorySearchAutocomplete
              key = {blockedCategoryAutocompleteKey}
              selectedOption={field.value}
              onOptionSelect={(item) => handleBlockedCategoryOptionSelect(item)}
            />
              <Button onClick={() => setOpenDialogBlockedCategories(true)}>
                Add Blocked categories in bulk
              </Button>
              {openDialogBlockedCategories ? (
                <BulkDialog open={openDialogBlockedCategories} fetchData={fetchBulkData} displayName={"category-name"}  setOpen={setOpenDialogBlockedCategories} title={"Blocked Categories"} control={control} selectedData={selectedBlockedCategories}  handleSave={handleBlockedCategoryOptionSelect}/>
              ) : null}
            </>
          )}
        />
      </Stack>
      <FixedSizeList height={300}
                     itemSize={46}
                     style={{ border: `1px solid ${strategyBlockedCategoryValue === 'exclude'  ? 'red' : strategyBlockedCategoryValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
                     itemCount={selectedBlockedCategories.length}
                     overscanCount={5}
                     itemData={selectedBlockedCategories}
      >
        {renderBlockedCategoryRow}
      </FixedSizeList>
      </IncludeExcludeOverlay>
        <Divider/>
      <IncludeExcludeOverlay {...props} strategyKey={"deal.conditions-json.domainOrBundle.strategy"} name={bundles} >
        <Typography style={{paddingTop: 12, paddingBottom:24}}>{bundles}</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Controller
          name="deal.conditions-json.domainOrBundle.strategy"
          control={control}
          render={({ field }) => (
            <FormControl style={{width: 140}}>
              <InputLabel id="add-dorb-i-e-label">Include/Exclude</InputLabel>
            <Select id={"add-dorb-i-e"} labelId={"add-dorb-i-e-label"}  {...field} value={field.value} label="Include/Exclude" onChange={() => {
              const value = field.value === StrategyType.include
                ? StrategyType.exclude
                : StrategyType.include;
              field.onChange(value);
            }}>
              <MenuItem value={StrategyType.include}>Include</MenuItem>
              <MenuItem value={StrategyType.exclude}>Exclude</MenuItem>
            </Select>
            </FormControl>
          )}
        />
        <Controller
          name="deal.conditions-json.domainOrBundle.value"
          control={control}
          render={({ field}) => (
            <>
              <TextField
                {...field}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                  }
                }}
                value={tempDomainOrBundleInput}
                onChange={(e) => setTempDomainOrBundleInput(e.target.value)}
                error={!!inputError}
                helperText={inputError}
                style={{ width: 300 }}
                label={"Enter a valid Android or IOS bundle id"}
              />
              <Button
                variant="contained"
                size={"large"}
                style={{height: 56, width: 96}}
                color="primary"
                onClick={() => handleAddDomainOrBundle(null)}
              >
                Add
              </Button>
              <Button onClick={() => setOpenDialogBundles(true)}>
                Add Bundles in bulk
              </Button>
              {openDialogBundles ? (
                <BulkDialog open={openDialogBundles} fetchData={checkValidationsBundlesBulk} setOpen={setOpenDialogBundles} title={"Bundles"} control={control} selectedData={selectedDomainsOrBundles}  handleSave={handleAddDomainOrBundle}/>
              ) : null}
            </>
          )}
        />
      </Stack>
      <FixedSizeList height={300}
                     itemSize={46}
                     style={{ border: `1px solid ${strategyDomainsOrBundlesValue === 'exclude'  ? 'red' : strategyDomainsOrBundlesValue === 'include' ? 'green' : "black"}`, margin: '10px 0' }}
                     itemCount={selectedDomainsOrBundles.length}
                     overscanCount={5}
                     itemData={selectedDomainsOrBundles}
      >
        {renderDomainOrBundleRow}
      </FixedSizeList>
      </IncludeExcludeOverlay>
      </CardContent>
    </Card>
  )
}
