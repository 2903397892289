import { useEffect, useState, Fragment } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { CategoryItem } from "./addSupply";

interface CategoryAutocompleteProps {
  onOptionSelect: (item: CategoryItem | null) => void;
  selectedOption?: CategoryItem | null;
}

export default function CategorySearchAutocomplete(props: CategoryAutocompleteProps) {
  const {onOptionSelect, selectedOption} = props
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly CategoryItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("")

  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const getNoOptionsText = () => {
    if (loading) return "Loading...";
    if (!inputValue.trim()) return "Start typing to view results";
    if (dataFetched && options.length === 0) return `No results for "${inputValue}"`;
    return null;
  };

  const fetchData = async (searchTerm:string) => {
    const response = await fetch(`/api/deals-aux/search-categories?q=${encodeURIComponent(searchTerm)}`, {
      method: "get",
      mode: "cors"
    })
    const json = await response.json();
    if(!json){
      return []
    }
    return json
  }

  useEffect(() => {
    let active = true;

    if (searchTerm === "") {
      setOptions([])
      setDataFetched(false);
      setLoading(false);
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const results = await fetchData(searchTerm);

        if (active) {
          setOptions([...results]);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-category"
      sx={{ width: 300 }}
      open={open}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption ?? null} // Ensure controlled component with nullish coalescing
      onChange={(_, newValue) => {
        onOptionSelect(newValue);
      }}
      isOptionEqualToValue={(option, value) => {
        return option["category-id"] === value["category-id"];
      }}
      getOptionLabel={(option) => {
        if (!option) return ''; // Return an empty string if no option is selected
        const name = option["category-name"] || '';
        const description = option["category-description"] ? `(${option["category-description"]})` : '';
        return `${name} ${description}`.trim();
      }}
      options={options}
      loading={loading}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setSearchTerm(newInputValue);

        if (newInputValue.trim() === "") {
          setDataFetched(false);
          setOptions([]); // Clear the options when the search term is cleared.
        }
      }}
      filterOptions={(x) => x}
      noOptionsText={getNoOptionsText()}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter a category"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
