import { MockedPath } from "@dtx/ui-core/src/mock/browser";
import dayjs from 'dayjs'


const mockBundlesList = [
	{bundleListName: {name: "bundleList1", createdBy: "shahar kimhi", id: "143434"} ,
		lastUpdate: dayjs().format('DD/MM/YY'),
		bundles: ["com.blabla","58279047803456780t34"],
	},
	{
		bundleListName: {name: 'bundleList2', createdBy: "shahar kimhi", id: "143435"} ,
		lastUpdate: dayjs().format('DD/MM/YY'),
		bundles: ["58279047803456780t34","com.blabla"],
	},
];

const mockdealsList = [
	{status: "Activated" ,
		dealId: "DTX123456232" ,
		dealName: "Deal one" ,
		description: "Test deal1" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Normal Deal" ,
		createdBy: "Dory" ,
	},
	{status: "Stopped" ,
		dealId: "DTX123434" ,
		dealName: "Effys Deal" ,
		description: "Test deal2" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Special Deal" ,
		createdBy: "Effy" ,
	},
	{status: "Pending" ,
		dealId: "DTX123455" ,
		dealName: "Effys Deal 3" ,
		description: "Test deal3" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Special Deal" ,
		createdBy: "Effy" ,
	},
	{status: "Expired" ,
		dealId: "DTX1237" ,
		dealName: "Effys Deal 4" ,
		description: "Test deal4" ,
		startDate: dayjs().format('DD/MM/YY') ,
		endDate: dayjs().format('DD/MM/YY'),
		dealType: "Regular Deal" ,
		createdBy: "Effy" ,
	},
];



const bundleList = new MockedPath("/bundleList", JSON.stringify(mockBundlesList));

const dealList = new MockedPath("/dealList", JSON.stringify(mockdealsList));


export const mockedPaths = (): Array<MockedPath> => {
	return [bundleList,dealList];
};
