import Table, { DEFAULT_PAGE_SIZE } from "../bundleListTable/Table";
import { useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import DealHeader from "./DealHeader";
import { useNavigate } from "react-router-dom";
import { DealsActionsCell } from "../dealsForm/dealsActionsCell";
import { Deal } from "../dealsForm/deal";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { AsyncQueryStatus, AsyncStore ,AsyncStoreResult} from "@dtx/ui-core/src/query";
import '../shared/styles/loadingStyles.css';

interface Props {
  filter?: string
  store: AsyncStore
}

interface DataInterface {
  data: Deal[];
  'result-count': number;
  'total-count': number;
  'page-number': number;
  'total-pages': number;
}

export type fetchMode = "success" | "loading" | "error"
export default function DealListTable(props: Props) {

  const navigate = useNavigate()


  const [filter, setFilter] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0)
  const [refreshKey, setRefreshKey] = useState(0)

  let searchTimeOutId: NodeJS.Timeout = -1
  useEffect(() => {
      result.refetch()
  }, [refreshKey])

  const key = `deals-${rowsPerPage}-${filter}-${selectedStatus}-${pageNumber}`
  const result: AsyncStoreResult<DataInterface | null> = props.store.addQuery(
    key,
    async () => {
      const encodedFilter = encodeURIComponent(filter)
      const encodedStatus = encodeURIComponent(selectedStatus)
      const response = await fetch(`/api/deals?numOfItems=${rowsPerPage}&page=${pageNumber}&sortBy=id&sortOrder=DESC&filters[text]=${encodedFilter}&filters[state]=${encodedStatus}`, {
        method: 'get',
        mode: 'cors',
      });
      if (response && response.status === 200) {
        const json = await response.json();
        return json;
      }

      throw new Error('Error fetching data');
    }
  );


  const clearSearchDebounce = () => {
    if (searchTimeOutId != -1) {
      clearTimeout(searchTimeOutId)
      searchTimeOutId = -1
    }
  }

  const handleFilterChange = async (value) => {
    clearSearchDebounce()
    searchTimeOutId = setTimeout(async function () {
      clearSearchDebounce()
      setFilter(value);
      setPageNumber(1)
      setTotalPages(1)
      const newKey = `deals-${rowsPerPage}-${value}-${selectedStatus}-1`
      await props.store.invalidateQuery(newKey)
    }, 500)

  };



  const handlePageChange = (pageIndex: number) => {

    const newPage = pageIndex + 1;
      setPageNumber(newPage)
  };
  const statuses = ['All', 'Activated', 'Stopped', 'Pending','Expired'];
  const createNewDeal = () => {
    navigate('/deals/edit')
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage)
  };

  const getPricingAdjustment = (deal) => {
    const total = Object.values(deal["fees-json"]).reduce((acc, value) => acc + value, 0)
    const priceAdjustment = total !== 27 ? 1 : 0
    return priceAdjustment;
  };
  const editDeal = async (id: number) => {
    let dealToEdit = result.data!.data.find((element) => element.id === id)
    const priceAdjustment = getPricingAdjustment(dealToEdit)
    dealToEdit["pricing-adjustment"] = priceAdjustment
    const dealId = dealToEdit["deal-id"]

    const demand = await fetch(`/api/deals/get-networks?dealId=${encodeURIComponent(dealId)}`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
      })
    dealToEdit.demand = await demand.json()
    if (dealToEdit) {
      navigate(`/deals/edit/${id}`, { state: { deal: dealToEdit, refreshKey: key}})
    } else {
      console.error(`Deal with id ${id} not found`)
    }
  }

  const handleStatusFilterChange = (status: string) => {
    setSelectedStatus(status);
    setPageNumber(1)
  };

  const statusStyles = {
    activated: {
      backgroundColor: '#3FFF0020',
    },
    stopped: {
      backgroundColor: '#fd5c6320',
    },
    pending: {
      backgroundColor: '#FFFFE0',
    },
    // Fallback style for unexpected status values
    default: {
      backgroundColor: 'transparent',
    },
  };

  const handleRowClick = (dealList: any) => {
    // setSelectedRow(bundleList)
  }

  const dealTypeMap: { [key: string]: string } = {
    '1': 'Preferred Deal',
    '2': 'Private Marketplace',
    '3': 'Public Package',
  };

  dayjs.extend(utc);

  const formatDate = (isoDate) => {
    if (isoDate) {
      // Parse the ISO date string as UTC and format it
      return dayjs.utc(isoDate).format('DD-MM-YYYY');
    }
    return '';
  };


  const columns = [
    { id: 'status',size: 60,accessor: 'status', header: 'Status',cell: ({ row }) =>{
        return  row.original.state}
    },
    { id: 'dealId',size: 60,accessor: 'dealId', header: 'Deal Id',cell: ({ row }) =>{
        return  row.original['deal-id']}
    },
    { id: 'dealName',size: 60,accessor:'dealName', header: 'Deal Name',cell: ({ row }) => {
        return row.original['deal-free-input-name']}
    },
    { id: 'description',size: 60,accessor: 'description', header: 'Description',cell: ({ row }) =>{
        return  row.original.description}
    },
    { id: 'startDate',size: 50,accessor: 'startDate', header: 'Start Date',cell: ({ row }) =>{
        return formatDate(row.original['from-date']);}
    },
    { id: 'endDate',size: 60,accessor: 'endDate', header: 'End Date',cell: ({ row }) =>{
        return formatDate(row.original['to-date']);}
    },
    // { id: 'dealType',size: 60,accessor: 'dealType', header: 'Deal Type',cell: ({ row  }) => {
    //     const dealTypeId = row.original['deal-type'];
    //     const dealTypeString = dealTypeMap[dealTypeId] || 'Unknown'; // Default to 'Unknown' if not found
    //     return dealTypeString;
    //   }
    // },
    { id: 'createdBy',size: 60,
      accessor: (row) => row['conditions-json']?.createdBy, header: 'Created By',cell: ({ row }) => {
        return row.original['conditions-json']?.createdBy; // Display the nested property
      }
    },
    // if I want to add action buttons to the side like bundles
    {
      id: 'actions',
      display: 'actions',
      size: 20,
      cell: ({ row }) => {
        const status = row.original.state;
        let actionsNames: string[] = ['Edit Deal'];
        if (status === 'Activated') {
          actionsNames.push('Stop Deal');
        } else if (status === 'Stopped') {
          actionsNames.push('Activate Deal');
        }
        else if (status === 'Pending') {
          // actionsNames.push('Activate Deal');
          actionsNames.push('Stop Deal');
        }

        return <DealsActionsCell row={row.original} actionsNames={actionsNames} callbackActions={[() => {
          editDeal(row.original['id'])
        }]} onUpdate={ result.refetch} />;
      },
    },
  ];
  return (

    <Grid container>
      <Grid item  style={{ paddingTop: '70px', width: '100%' }}>
        <Paper style={{ overflowX: 'auto' }}>
          <DealHeader
            title={'Deals Name'}
            placeholder={'Deals'}
            dealsSelect={true}
            onAddButtonClicked={() => createNewDeal()}
            statuses={statuses}
            selectedStatus={selectedStatus}
            onStatusChange={handleStatusFilterChange}
            onTextFilterChanged={handleFilterChange}
          />
          <Divider />
          {result.status === AsyncQueryStatus.loading || result.isFetching || result.isPending ? (
            <div id="load">
              <div>G</div>
              <div>N</div>
              <div>I</div>
              <div>D</div>
              <div>A</div>
              <div>O</div>
              <div>L</div>
            </div>
          ) :  result.status === AsyncQueryStatus.error || result.status === undefined ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          There was an error. Please try again.
        </div>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <Table
              id="deal-list-table"
              data={result.data.data ?? []}
              columns={columns}
              totalCount={result.data["total-count"]}
              totalPages={result.data["total-pages"]}
              currentPage={result.data["page-number"]}
              rowPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              manualPagination={true}
              onRowsPerPageChange={handleRowsPerPageChange}
              cellProps={{
                style: {
                  whiteSpace: 'nowrap',
                  padding: '0px'
                },
              }}
              getRowProps={(row) => {
                const dealList = row.original;
                let statusClass = ''; // Default class for rows with 'pending' status

                if (dealList.state === 'Activated') {
                  statusClass = 'activated';
                } else if (
                  dealList.state === 'Stopped' ||
                  dealList.state === 'Expired'
                ) {
                  statusClass = 'stopped';
                } else if (dealList.state === 'Pending') {
                  statusClass = 'pending';
                }
                return {
                  style: {
                    cursor: 'pointer',
                    ...statusStyles[statusClass] ?? statusStyles.default,
                  },
                  onClick: () => handleRowClick(dealList),
                };
              }}
            />
          </div>)}
        </Paper>
      </Grid>
    </Grid>
  );
}
