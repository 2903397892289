import { useEffect, useState, Fragment } from "react";
import { Autocomplete, AutocompleteValue, CircularProgress, TextField } from "@mui/material";
import { GeoSearchItem } from "./geographicTargeting";

interface GeoSearchAutocompleteProps {
  onOptionSelect: (item: AutocompleteValue<GeoSearchItem, false, false, false>) => void;
  selectedOption?: GeoSearchItem[] | null;
}

export default function GeoSearchAutocomplete(props: GeoSearchAutocompleteProps) {
  const {onOptionSelect, selectedOption} = props
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly GeoSearchItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const getNoOptionsText = () => {
    if (loading) return "Loading...";
    if (!inputValue.trim()) return "Start typing to view results";
    if (dataFetched && options.length === 0) return `No results for "${inputValue}"`;
    return null; // When options are available or before search starts
  };

  const fetchData = async (searchTerm:string) => {
    const response = await fetch(`/api/geo/search?q=${encodeURIComponent(searchTerm)}`, {
      method: "get",
      mode: "cors"
    })
    const json = await response.json();
    return json
  }

  useEffect(() => {
    let active = true;

    if (searchTerm === "") {
      setOptions([])
      setDataFetched(false);
      setLoading(false);
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const results = await fetchData(searchTerm);

        if (active) {
          setOptions([...results]);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-geo"
      sx={{ width: 300 }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption ?? null}
      onChange={(event, newValue) => {
        if (newValue !== null) { // Check if newValue is not null
          onOptionSelect(newValue);
        }
      }}
      isOptionEqualToValue={(option, value) => option["display-name"] === value["display-name"]}
      getOptionLabel={(option) => (option as GeoSearchItem)?.["display-name"] || ''}
      options={options}
      loading={loading}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setSearchTerm(newInputValue);

        if (newInputValue.trim() === "") {
          setDataFetched(false);
          setOptions([]);
        }
      }}
      filterOptions={(x) => x}
      noOptionsText={getNoOptionsText()}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Enter a geo"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </Fragment>

              ),
            }}
          />
        );
      }}
    />
  );
}
