import {
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  Box,
  Alert,
  Typography,
  TextField, Select, FormHelperText, Grid, Stack, Switch
} from "@mui/material";
import { Controller } from "react-hook-form";
import utc from "dayjs/plugin/utc";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import { forwardRef, useEffect } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Deal, FeesJson } from "./deal";
import { DealPriceType } from "./models/dealPriceType";

dayjs.extend(utc);

interface BasicDealDetailsProps {
  control: any; // Replace with the correct type from react-hook-form
  fromDateValue: Date;
  formPricingModel: number;
  getValues: Function;
  setValue: Function;
  formPricingAdjustment: number;
  fees: FeesJson;
  formDealId: string;
  formAuctionType: number;
  formPriceStrategy: number;
  formDealIsPublic: boolean;
}

export enum DealType {
  "Preferred Deal" = 1,
  "Private Marketplace" = 2,
  "Public Package" = 3
}

export enum PricingModelType {
  "Fixed price" = 1,
  "Static floor" = 2,
 "Variable floor" = 3
}

export enum PricingAdjusment {
  "Default Margin" = 0,
  "Custom Margins" = 1
}


const numberToPricingModel = (type: number) => {
  return type === 1 ? "Fixed price" : type === 3 ? "Variable floor" : "Static floor";
};
const isCustomPricingAdjustment = (pricingAdjustment: PricingAdjusment) => {
  return pricingAdjustment === 1
};

const isPricingValueRequired = (type: PricingModelType) => {
  return type === 1 || type === 2;
};

const isPriceValueRequired = (type: PriceType) => {
  return type === 2;
};

export enum ViewabilityTags {
  "Exclude Viewability Tracking",
  "MOAT",
  "IAS",
  "Double Verify"
}

export const PriceType = {
  "Publisher Default price": 2,
  "Fixed price": 3
};


type FeesCallbackFunction = (feeName: string, value: number) => void


interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type DealFeesJsonPaths = `deal.fees-json.${keyof Deal["fees-json"]}`;

const NumericFormatAdjustment = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        valueIsNumericString
        suffix="%"
      />
    );
  }
);

const NumericFormatCash = forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$ "
      />
    );
  }
);

const generateLabelForFeeItem = (fee: string) => {
  switch (fee) {
    case "rebate":
      return "Rebate";
    case "resellerFee":
      return "Reseller Fee";
    case "dspFee":
      return "DSP Fee";
    case "techFee":
      return "Tech Fee";
    case "postAuctionDiscount":
      return "Post Auction Discount";
    case "other":
      return "Other";
    case "baselineMargin":
      return "Baseline Margin";
    case "additionalMargin":
      return "Additional Margin";
  }
};

const generateCustomPricingFields = (fees: FeesJson, cb: FeesCallbackFunction) => {
  return Object.keys(fees).map((feeItem, index) => {
    return (
      <Grid item xs={2}>
        <TextField
          label={generateLabelForFeeItem(feeItem)}
          value={Object.values(fees)[index]}
          onChange={(e) => {
            cb(feeItem, parseFloat(e.target.value));
          }}
          name={`custom-pricing-${feeItem}`}
          id={`custom-pricing-${feeItem}-input`}
          InputProps={{
            inputComponent: NumericFormatAdjustment as any
          }}
        />
      </Grid>
    );
  });
};

// const convertDateToUTC = (date) => {
//   return dayjs(date).utc().format('YYYY-MM-DD'); // Convert the date to UTC
// };


export default function BasicDealDetails(props: BasicDealDetailsProps) {
  const {
    control,
    fromDateValue,
    formPriceStrategy,
    formDealIsPublic,
    formPricingModel,
    formAuctionType,
    formDealId,
    setValue,
    getValues,
    formPricingAdjustment,
    fees
  } = props;

  useEffect(() => {
    if (formPricingAdjustment === 0) {
      Object.keys(fees).map((feeItem, index) => {
        setValue(`deal.fees-json.${feeItem}` as DealFeesJsonPaths,feeItem !== "baselineMargin" ? 0 :27)
      })
    }
  }, [formPricingAdjustment])
  const getPriceAdjustmentType = (value: PricingModelType) => {
    return (value !== undefined  && formPricingModel !== 3) ? value : 0
  }
  const dealName = props.getValues().deal[`deal-free-input-name`]
  return (
    <>
      <Typography variant="h6">Basic deal details</Typography>
      {formDealId !== "" ? (
        <>
          <InputLabel id={`deal-id-edit-deal`} style={{ paddingTop: "10px" }}>
            Deal Id
          </InputLabel>
          <FormControl fullWidth margin="normal">
            <TextField
              value={formDealId}
              disabled={true}
            />
          </FormControl>
        </>
      ) : null}
      {formAuctionType === 0 ? (formDealId === "" || !dealName.isDirty || dealName) && (<Controller
        name="deal.deal-free-input-name"
        control={control}
        rules={{
          required: "Deal Name is required",
          minLength: { value: 3, message: "Minimum length is 3" },
          maxLength: { value: 100, message: "Maximum length is 100" }
        } as const}
        render={({ field, fieldState }) =>
          (
            <FormControl fullWidth margin="normal">
              <TextField
                style={{ backgroundColor: "white" }}
                {...field}
                label="Deal Name"
                value={field.value}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            </FormControl>
          )}
      />) : null}
      <Controller
        name="deal.description"
        control={control}
        rules={{
          minLength: { value: 3, message: "Minimum length is 3" },
          maxLength: { value: 200, message: "Maximum length is 200" }
        }}
        render={({ field, fieldState }) => (
          <FormControl fullWidth margin="normal">
            <TextField
              {...field}
              style={{ backgroundColor: "white" }}
              label="Deal Description"
              value={field.value || ""}
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ""}
            />
          </FormControl>
        )}
      />

      <Card variant={"outlined"} style={{ marginTop: 12 }}>
        <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Deal run time"} />
        <CardContent style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <Controller
            name="deal.from-date"
            control={control}
            render={({ field, fieldState }) =>
              (
              <FormControl fullWidth>
                <DatePicker
                  format="YYYY-MM-DD"
                  label={"Start Date"}
                  value={field.value ? dayjs(field.value).utc(false) : null}
                  onChange={(date: Date | null) => {
                    if (date && dayjs(date).isAfter(dayjs(), "day")) {
                      setValue("deal.active", true)
                    } else {
                      setValue("deal.active", false)

                    }
                    return field.onChange(date.toISOString())
                  }}
                  minDate={dayjs(new Date()).utc(false)}
                />
              </FormControl>
            )}
          />
          <span>To</span>
          <Controller
            name="deal.to-date"
            control={control}
            render={({ field, fieldState }) =>
              (
              <FormControl fullWidth>
                <DatePicker
                  format="YYYY-MM-DD"
                  label={"End Date"}
                  value={field.value ? dayjs(field.value).utc(false) : null}
                  onChange={(date: Date | null) => {
                    if (date && dayjs(date).isAfter(dayjs(), "day")) {
                      setValue("deal.active", true)
                    } else {
                      setValue("deal.active", false)

                    }
                    return field.onChange(date.toISOString())
                  }}
                  minDate={dayjs(fromDateValue).utc(false)}
                />
              </FormControl>
            )}
          />
        </CardContent>
      </Card>
      {(formAuctionType === 0 || formAuctionType === undefined) ? (
        <Card variant={"outlined"} style={{ marginTop: 12 }}>
          <CardHeader titleTypographyProps={{ variant: "caption" }} title={"Pricing Model"} />
          <CardContent style={{ display: "flex", flexDirection: "column" }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  name="deal.pricing-type"
                  control={control}
                  render={({ field }) => (
                    <FormControl fullWidth disabled={formDealId !== ""}>
                      <InputLabel id="pricing-model-select-label">Pricing model type</InputLabel>
                      <Select
                        label={"Pricing model type"}
                        labelId={"pricing-model-select-label"}
                        id="pricing-model-select"
                        value={field.value}
                        onChange={(event) => {
                          const value = parseInt(event.target.value)
                          setValue("deal.pricing-type", value)
                          if(value === 3 ) {
                            setValue("deal.pricing-adjustment", 0)
                          }
                          field.onChange(value);
                        }}
                      >
                        {Object.keys(PricingModelType).filter((v) => isNaN(Number(v))).map((key, index) => (
                          <MenuItem value={index + 1}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                {isPricingValueRequired(formPricingModel) && (
                  <Controller
                    name="deal.bid-floor-price"
                    rules={{
                      required: "This field is required",
                      min: { value: 0.01, message: "Pricing model floor price should be at least 0.01" },
                      max: { value: 100, message: "Pricing model floor price should be at most 100" }
                    }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          InputProps={{
                            inputProps: {
                              min: 0.01,
                              max: 100
                            },
                            inputComponent: NumericFormatCash as any
                          }}

                          label={formPricingModel === PricingModelType["Fixed price"] ? "Fixed price value" : "Static floor value"}
                          onChange={(event) => {
                            const value = parseFloat(event.target.value);
                            setValue("deal.bid-floor-price", value);
                            field.onChange(value);
                          }}
                        />
                        {fieldState.error && <FormHelperText error>{fieldState.error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="deal.pricing-adjustment"
                  control={control}
                  render={({ field }) => {
                    const pricingAdjustmentValue = getPriceAdjustmentType(field.value)
                    return (

                      <FormControl fullWidth disabled={formPricingModel === 3}>
                        <InputLabel id="pricing-adjustment-select-label">Pricing Adjustment type</InputLabel>
                        <Select
                          labelId="pricing-adjustment-select-label"
                          id="pricing-adjustment-select"
                          label="Pricing Adjustment type"
                          value={pricingAdjustmentValue}
                          {...field}
                        >
                          {Object.keys(PricingAdjusment).filter((v) => isNaN(Number(v))).map((key, index) => (
                            <MenuItem value={PricingAdjusment[key]} key={index}>{key}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                    );
                  }}
                />
              </Grid>
            </Grid>

            {isCustomPricingAdjustment(formPricingAdjustment) && (
              <Box style={{ marginTop: 16 }}><Grid container spacing={{ xs: 2, md: 3 }}
                                                   columns={{ xs: 4, sm: 8, md: 12 }}>

                {generateCustomPricingFields(fees, (feeName, newValue) => {
                  const current = getValues(`deal.fees-json`) as DealFeesJsonPaths;
                  current[feeName] = newValue;
                  const sumOfFees: number = Object.values(current).reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);
                  if (sumOfFees > 100 || sumOfFees < 0) {
                    console.error("Total fees cannot exceed 100");
                    setValue(`customMarginError`, "Total fees cannot exceed 100");
                    setValue(`deal.fees-json.${feeName}` as DealFeesJsonPaths, newValue);
                  } else {
                    setValue(`customMarginError`, null);
                    setValue(`deal.fees-json.${feeName}` as DealFeesJsonPaths, newValue);
                  }
                })}

              </Grid>
                {getValues(`customMarginError`) && (
                  <Alert style={{ margin: 12 }} severity="error">{getValues(`customMarginError`)}</Alert>)}
              </Box>
            )}

          </CardContent>
        </Card>) : (
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>Pricing</span>
            <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
              <Controller
                name="deal.auction-type"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControl fullWidth>
                      <Select
                        value={formPriceStrategy ? DealPriceType.fromAuctionTypeAndPriceStrategy(field.value, formPriceStrategy) : DealPriceType.fromAuctionTypeAndPriceStrategy(2, 1)}
                        labelId="price-model-select-label"
                        id="price-model-select"
                        // label="Pricing model"
                        {...field}
                      >
                        {Object.keys(PriceType).map((key, index) => (
                          <MenuItem value={PriceType[key]}>{key}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />
              {isPriceValueRequired(formAuctionType) && (
                <Controller
                  name="deal.bid-floor-price"
                  control={control}
                  rules={{
                    min: { value: 0.01, message: "Pricing model floor price should be at least 0.01" },
                    max: { value: 100, message: "Pricing model floor price should be at most 100" }
                  }}
                  render={({ field }) => (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          {...field}
                          InputProps={{
                            inputProps: {
                              min: 0.01
                            },
                            inputComponent: NumericFormatCash as any
                          }}
                          variant="standard"
                          onChange={(event) => {
                            const value = parseFloat(event.target.value);
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                    </>
                  )}
                />
              )
              }
            </div>
          </div>
        </>
      )
      }


      <Controller
        name="deal.viewability-tag"
        control={control}
        render={({ field }) => (
            <FormControl fullWidth style={{marginTop: 16}}>
              <InputLabel htmlFor="viewability-tag-select-label" >Viewability Tag</InputLabel>
              <Select
                label={"Viewability Tag"}
                {...field}
                labelId="viewability-tag-select-label"
                id="viewability-tag-select"
                style={{backgroundColor: 'white'}}
              >
                {Object.entries(ViewabilityTags)
                  .filter(([key, value]) => !isNaN(Number(value)))
                  .map(([key, value]) => (
                    <MenuItem value={value} key={key}>
                      {key}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

        )}
      />

    </>

  );
}
