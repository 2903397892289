import { useEffect, useState, Fragment } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { PublisherItem, PublisherType } from "./addSupply";

interface PublisherAutocompleteProps {
  onOptionSelect: (item: PublisherItem[] | null) => void;
  selectedOption?: PublisherItem | null;
}

export default function PublisherSearchAutocomplete(props: PublisherAutocompleteProps) {
  const {onOptionSelect, selectedOption} = props
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly PublisherItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  const getNoOptionsText = () => {
    if (loading) return "Loading...";
    if (!inputValue.trim()) return "Start typing to view results";
    if (dataFetched && options.length === 0) return `No results for "${inputValue}"`;
    return null;
  };

  const fetchData = async (searchTerm:string) => {
    const response = await fetch(`/api/deals-aux/search-publishers?q=${encodeURIComponent(searchTerm)}`, {
      method: "get",
      mode: "cors"
    })
    const json = await response.json();
    if(!json){
      return []
    }
    return json
  }

  useEffect(() => {
    let active = true;
    if (searchTerm === "") {
      setOptions([])
      setDataFetched(false);
      setLoading(false);
      return undefined;
    }

    setLoading(true);

    (async () => {
      try {
        const results = await fetchData(searchTerm);

        if (active) {
          setOptions([...results]);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, [searchTerm]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const getGroupedAppOptions = (items: readonly PublisherItem[]) => {
    // ...existing code...

    // Map to hold one item per unique app name
    const appItemsByName = new Map();
    const publishers: PublisherItem[] = [];
    const adSpots: PublisherItem[] = [];
    const adUnits: PublisherItem[] = [];

    items.forEach(item => {
      switch (item["publisher-type"]) {
        case PublisherType.App:
          if (!appItemsByName.has(item["app-name"])) {
            appItemsByName.set(item["app-name"], item);
          }
          break;
        case PublisherType.Publisher:
          publishers.push(item);
          break;
        case PublisherType.AdSpot:
          adSpots.push(item);
          break;
        case PublisherType.AdUnit:
          adUnits.push(item);
          break;
        // Additional cases if necessary
      }
    });

    const groupedOptions = [
      ...publishers,
      ...Array.from(appItemsByName.values()),
      ...adSpots,
      ...adUnits,
    ].map((item, index) => {
      const uniqueKey = `${item["publisher-id"]}-${item["publisher-type"]}-${item["unit-id"] || ''}-${index}`;
      return { ...item, uniqueKey };
    });

    return groupedOptions;
  };


  const handleOptionSelect = (newValue: PublisherItem | null) => {
    setSearchTerm("");
    if (newValue) {
      if (newValue["publisher-type"] === PublisherType.App) {
        // Fetch all related App items
        const relatedApps = options.filter(option =>
          option["publisher-type"] === PublisherType.App && option["app-name"] === newValue["app-name"]
        );
        onOptionSelect(relatedApps);
      } else {
        onOptionSelect([newValue]);
      }
    } else {
      onOptionSelect(null);
    }
  };


  return (
    <Autocomplete
      id="asynchronous-publisher"
      sx={{ width: 400 }}
      open={open}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption ?? null} // Ensure
      onChange={(_, newValue: PublisherItem | null) => {
        if (newValue) {
          handleOptionSelect(newValue);
        } else {
          handleOptionSelect(null);
        }
      }}
      isOptionEqualToValue={(option, value) => {
        if (value["publisher-type"] === "Publisher") {
          return option["publisher-id"] === value["publisher-id"];
        } else {
          return option["unit-id"] === value["unit-id"];
        }
      }}
      getOptionLabel={(option) => {
        if (!option) return '';
        switch (option["publisher-type"]) {
          case PublisherType.AdUnit:
            return `${option["app-name"] || ''} - ${option["unit-id"] || ''} (${option["publisher-type"] || ''})`.trim();
          case PublisherType.AdSpot:
            return `${option["app-name"] || ''} - ${option["unit-id"] || ''} (${option["publisher-type"] || ''})`.trim();
          case PublisherType.App:
            return `${option["app-name"] || ''} (${option["publisher-type"] || ''})`.trim();
          default:
            const name = option["publisher-name"] || '';
            const code = option["publisher-id"] ? `(${option["publisher-id"]}) ${option["publisher-type"]}` : '';
            return `${name} ${code}`.trim();
        }
      }}
      options={getGroupedAppOptions(options)}
      loading={loading}
      // onInputChange={(_, newInputValue) => {
      //   setSearchTerm(newInputValue)
      // }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setSearchTerm(newInputValue);

        if (newInputValue.trim() === "") {
          setDataFetched(false);
          setOptions([]);
        }
      }}
      filterOptions={(x) => x}
      noOptionsText={getNoOptionsText()}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Enter a Publisher name/ ID"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
}
