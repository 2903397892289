//created using https://transform.tools/json-to-typescript

export interface Deal {
  id: string
  "deal-id": string,
  "deal-description": string,
  "from-date": Date
  "to-date": Date
  "bid-floor-price": number
  active: boolean
  "conditions-json": ConditionsJson
  "is-public": boolean
  "deal-type": number
  "created-by": number
  "viewability-tag": number
  "fees-json": FeesJson
  "pricing-type": number
  "publisher-id": number,
  "pricing-adjustment": number
  "deal-floor-price-strategy": number
  "auction-type": number
  "deal-free-input-name": string
  state: string
  demand: DemandItem
}

export interface DemandItem {
  networks: Network[];
}

export interface Network {
  seats?: string[];
  active?: boolean;
  "demand-id": number;
  "demand-name": string;
  "demand-display-name": string;
}

export interface ConditionsJson {
  age?: Age
  gender?: Gender
  gpsLocation?: GpsLocation
  geo: Geo
  appOrSite?: AppOrSite
  publishers: Publishers
  contents: Contents
  categories: Categories
  blockedCategories: BlockedCategories
  domainOrBundle: DomainOrBundle
  make?: Make
  os?: Os
  language: Language
  connectionType?: ConnectionType
  muted: Muted
  hasIFA: HasIfa
  dnt: Dnt
  deviceType: DeviceType
  adSizes: AdSizes
  skippableTraffic: SkippableTraffic
  omSupported: OmSupported
  rewarded: Rewarded
  displayType: DisplayType
  deviceOS: DeviceOs
  carrier?: Carrier
  createdBy: string
  audiences?: Audiences
}

export enum StrategyType {
  include = "include",
  exclude = "exclude"
}

export interface StrategyWrapper<T> {
  strategy: StrategyType
  value: T
}

export interface Age extends StrategyWrapper<string> {
}

export interface Gender extends StrategyWrapper<string> {
}

export interface GpsLocation extends StrategyWrapper<string> {
}

export interface Geo extends StrategyWrapper<any> {
}

export interface AppOrSite extends StrategyWrapper<string> {
}

export interface Publishers extends StrategyWrapper<any> {
}

export interface Contents extends StrategyWrapper<any> {
}

export interface Categories extends StrategyWrapper<any> {

}

export interface BlockedCategories extends StrategyWrapper<any> {
}

export interface DomainOrBundle extends StrategyWrapper<string[]> {
}

export interface Make extends StrategyWrapper<any> {
}

export interface Os extends StrategyWrapper<any> {
}

export interface Language extends StrategyWrapper<any> {
}

export interface ConnectionType extends StrategyWrapper<string> {
}

export interface Muted  extends StrategyWrapper<string> {

}

export interface HasIfa extends StrategyWrapper<string> {
}

export interface Dnt  extends StrategyWrapper<string> {

}

export interface DeviceType  extends StrategyWrapper<string> {
}

export interface AdSizes extends StrategyWrapper<string[]>  {
}

export interface SkippableTraffic extends StrategyWrapper<string> {
}

export interface OmSupported extends StrategyWrapper<string> {
}

export interface Rewarded extends StrategyWrapper<string> {

}

export interface DisplayType extends StrategyWrapper<string> {
}

export interface DeviceOs extends StrategyWrapper<string> {
}

export interface Carrier extends StrategyWrapper<any> {
}

export interface Audiences extends StrategyWrapper<string> {
}

export interface FeesJson {
  rebate: number,
  resellerFee: number,
  dspFee: number,
  techFee: number,
  postAuctionDiscount: number,
  other: number,
  baselineMargin: number,
  additionalMargin: number
}
